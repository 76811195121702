/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@ng-select/ng-select/themes/default.theme.css';
$material-symbols-font-path: 'material-symbols/';
@import 'material-symbols';

// cloudmed must come before bootstrap, since the reboot uses
// font settings in cloudmed
@import 'colors';
@import 'variables';
@import 'bootstrap/scss/bootstrap';
@import 'pagination';

html,
body {
  height: 100%;
  color: $r1-black;
}

a {
  color: $link-color;

  &:hover {
    text-decoration: underline;
  }

  .disabled {
    pointer-events: none;
    color: gray;
    text-decoration: none;
  }
}

h2,
h3 {
  color: $r1-blue;
}

h4,
h5,
h6 {
  color: $link-color;
}

.bg-light {
  background-color: $cloudmed-bright-gray;
}

.btn-primary,
.btn-secondary,
.btn-danger,
.bg-primary {
  color: white;

  &:hover, &:active {
    color: white;
  }
}

.breadcrumb {
  --bs-breadcrumb-bg: #{$gray-200};
}

.alert-info {
  background: $r1-gray-background;
  color: $r1-blue;
}

.card-body {
  padding: 0.75rem;
}

.btn-calendar {
  &:disabled {
    color: $cloudmed-teal;
    border-color: currentColor;
  }

  border: 1px solid #ced4da;
}

@keyframes flashfade {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.flashfade {
  animation-name: flashfade;
  animation-duration: 6s;
  display: block;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none;
}

.floatThead-container thead {
  background-color: #fff;
}

.capture-detail {
  font-size: 0.9rem;
}

.toast {
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 1);
}

.toast .close {
  @extend .btn
}

.modal {
  &.wide {
    .modal-dialog {
      max-width: 880px;
    }
  }

  &.edit-rmv-modal {
    .modal-dialog {
      max-width: 90%;
    }
  }

  &.graph-modal {
    .modal-dialog {
      max-width: 75%;
    }
  }

  &.image-view-modal {
    .modal-dialog {
      width: fit-content;
      max-width: 70%;
    }
  }

  .modal-header {
    background: $r1-blue;

    h4 {
      color: #FFF;
    }

    button.close {
      border: none;
      background: none;
      color: #FFF;
      font-size: 145%;
      outline: none;
    }
  }
}

.wrap-with-hyphens {
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;

  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.ng-select.ng-select-multiple {
  .ng-select-container .ng-value-container {
    max-width: calc(100% - 40px);

    .ng-value {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;

      .ng-value-label {
        display: inline;
      }
    }
  }

  .ng-clear-wrapper {
    position: absolute;
    right: 20px;
  }

  .ng-arrow-wrapper {
    position: absolute;
    right: 0;
  }
}

.generic-form {
  .row {
    margin: 0;
  }

  h5 {
    color: $r1-blue;
    font-weight: normal;
    margin: 15px 0;
    font-weight: 200;
  }

  .actions-column {
    padding: 15px 0;
  }

  label {
    color: $cloudmed-dark-charcoal;
    font-weight: 400;

    &.radio-option, &.checkbox-option {
      color: inherit;
    }

    input[type=radio] {
      margin-left: 10px;
      margin-right: 5px;
    }

    span {
      vertical-align: middle;
    }

    &:first-child {
      input[type=radio] {
        margin-left: 0;
      }
    }
  }
}

.small-gutters {
  > [class*="col-"] {
    padding-left: 8px;
    padding-right: 8px;
  }
}

#consultNoteDetailPage {
  font-size: 14px;
  position: relative;
}

input:disabled,
textarea:disabled,
.ng-select.ng-select-disabled > .ng-select-container
{
  background-color: #f9f9f9;
}

.ng-select.is-invalid .ng-select-container {
  border-color: $cloudmed-alert-danger;
}


.ng-select.auto-width-dropdown {
  .ng-dropdown-panel {
    width: auto !important;
    max-width: 1000px;
    min-width: 100%;
  }
}

input[type="checkbox" i],
input[type="radio" i] {
  cursor: pointer;
}
input[type="checkbox" i][disabled],
input[type="radio" i][disabled] {
  cursor: default;
}

.table-dark {
  --bs-table-bg: #{$cloudmed-charcoal};
  --bs-table-border-color: #{$cloudmed-charcoal};
}

.toast-container {
  // set a value of 20k so this is above the default pendo badge value of 19k (:facepalm:)
  --bs-toast-zindex: 20000;
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  vertical-align: middle;
}
