@font-face {
  font-family: "Campton Light";
  src: url("../assets/fonts/Campton-Light.otf") format("opentype");
}

@font-face {
  font-family: "Campton Book";
  src: url("../assets/fonts/Campton-Book.otf") format("opentype");
}

/* allows us to overwrite bootstrap variables */
$font-family-sans-serif: 'Campton Book', 'Arial', sans-serif;

/*
  Boostrap works with rem (not px) values.
  0.9375rem = 15px

  See: https://github.com/twbs/bootstrap/issues/23982
*/
$font-size-base: 0.9375rem;

$body-color: $cloudmed-dark-charcoal;

$link-decoration: none;

$table-bg-dark: $cloudmed-charcoal;
$table-border-dark: $cloudmed-charcoal;
