@import 'colors';

.page-item {

  .page-link {
    color: $pagination-blue-link;
  }

  &.active {
    .page-link {
      background-color: $gray-light;
      color: inherit;
      border: 1px solid $cloudmed-dark-gray;
    }
  }
}
